import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import MobilekeyMenual from './MobilekeyMenual';
import CheckOut from './CheckOut';
import * as GoogleAnalytics from '../../lib/google-analytics';
import { roomNoFormatter, roomNoFormatter2 } from '../../lib/formatter';
import { logDataApi } from '../../lib/logDataApi';


const BookingList = ({
  bookingItem,
  checkOut,
}:any) => {
  const [ isOpenModalMobilekeyMenual, setIsOpenModalMobilekeyMenual ] = useState(false);
  const [ isOpenModalCheckOut, setIsOpenModalCheckOut ] = useState(false);

  const openModalMobilekeyMenual = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_mobilekey_menual',
    });
    setIsOpenModalMobilekeyMenual(true);
  }, []);

  const closeModalMobilekeyMenual = useCallback(() => {
    setIsOpenModalMobilekeyMenual(false);
  }, []);

  const openModalCheckout = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_request',
    });
    
    if(bookingItem.rsvnNo) logDataApi({rsvnNo: bookingItem.rsvnNo, progress: '5', ref: 'Y'});

    setIsOpenModalCheckOut(true);
  }, [bookingItem.rsvnNo]);

  const closeModalCheckOut = useCallback(() => {
    setIsOpenModalCheckOut(false);
  }, []);

  const dayFormatter = (day:string) => {
    return {
      Sun: '일',
      Mon: '월',
      Tue: '화',
      Wed: '수',
      Thu: '목',
      Fri: '금',
      Sat: '토',
    }[day] || day;
  };

  const setBookingPeriod = (date:string|Date) => {
    return `${moment(date).format('YYYY.MM.DD')} (${dayFormatter(moment(date).format('ddd'))})`;
  };

  return (
    <Fragment>
      <div className='checked-in-background-container'>
        <div className='opacity-background'>
          <span className='welcome-message'>
            {bookingItem.guestName} 고객님,<br/>
            환영합니다.
          </span>
          <div className='booking-id-container'>
            <span className='booking-id-label'>
              예약번호
            </span>
            <span className='booking-id-value'>
              {bookingItem.rsvnNo}
            </span>
          </div>
        </div>
      </div>
      <div className='checked-in-booking-card-container'>
      <span className='hotel-name'>
        탑스텐리조트 동강시스타
          {/* {bookingItem.propertyName} {bookingItem.zoneTypeName} */}
        </span>
        <span className='hotel-name' style={{marginTop: '0.5rem',fontSize: '1rem'}}>
          [{roomNoFormatter2(bookingItem.roomNo)}]
        </span>
        <span className='room-name'>
          {roomNoFormatter(bookingItem.roomNo)}
        </span>
        <div className='horizontal-line mt-15 mb-20'></div>
        <div className='userkey-container'>
          <div className='userkey-item mb-15'>
            <span className='userkey-item-label'>
              객실 비밀번호
            </span>
            <span className='userkey-item-value'>
              {bookingItem.entranceNo ? `${bookingItem.entranceNo}*` : '-'}
            </span>
          </div>
          <div className='userkey-item'>
            <span className='userkey-item-label'>
              모바일키
              <img className='tip-icon ml-2 mb-4' src='../../assets/images/question-mark.png' alt='' onClick={openModalMobilekeyMenual} />
            </span>
            <span className='userkey-item-value'>
              {bookingItem.mobileRoomKey ? bookingItem.mobileRoomKey : '-'}
            </span>
          </div>
        </div>
        <div className='checked-in-booking-period-container'>
          <img className='period-icon mt-2' src='../../assets/images/period.png' alt='' />
          <div className='period-item-container'>
            <div className='period-item mb-8'>
              <span className='period-item-label'>
                체크인
              </span>
              <span className='period-item-value'>
                {bookingItem.arrvDate ? `${setBookingPeriod(bookingItem.arrvDate)} 15:00` : '-'}
              </span>
            </div>
            <div className='period-item'>
              <span className='period-item-label'>
                체크아웃
              </span>
              <span className='period-item-value'>
                {bookingItem.deptDate ? `${setBookingPeriod(bookingItem.deptDate)} 11:00` : '-'}
              </span>
            </div>
          </div>
        </div>
        <div className='check-out-button-container'>
          <span className='check-out-button' onClick={openModalCheckout}>
            모바일 체크아웃 하기
          </span>
        </div>
      </div>
      <MobilekeyMenual isOpen={isOpenModalMobilekeyMenual} onClose={closeModalMobilekeyMenual} />
      <CheckOut isOpen={isOpenModalCheckOut} onClose={closeModalCheckOut} checkOut={checkOut} />
    </Fragment>
  );
};

export default BookingList;