// 현재 파일은 기본 제공 디자인입니다. 수정하지 말아주세요.
import styled from "styled-components";
import RoomSelectContainer from "./containers/RoomSelectContainer";

//예약 첫 화면
const RoomSelect = styled(RoomSelectContainer)`
  .room-main-title {
    display: block;
    padding: 16px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #333;
  }
  .room-container {
    padding: 16px;
  }
  .building-list-container {
    display: flex;
    margin: 0 0 10px 0;
    padding: 10px 0;
    list-style: none;

    li {
      margin-right: 12px;
      padding: 6px;
      border: 1px solid #333;
      border-radius: 5px;
      cursor: pointer;

      &.click-on {
        color: #fff;
        background: #333;
      }
    }
  }
  .floor-list-container {
    display: flex;
    margin: 0 0 10px 0;
    padding: 10px 0;
    list-style: none;
    overflow: auto;

    li {
      margin-right: 12px;
      padding: 6px;
      min-width: 60px;
      text-align: center;
      border: 1px solid #333;
      border-radius: 5px;
      cursor: pointer;

      &.click-on {
        color: #fff;
        background: #333;
      }
    }
  }
  .room-list-container {
    display: flex;
    margin: 0 0 10px 0;
    padding: 10px 0;
    list-style: none;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      margin: 8px 0;
      padding: 6px;
      min-width: 60px;
      width: calc(50% - 6px);
      text-align: center;
      border: 1px solid #333;
      border-radius: 4px;
      cursor: pointer;

      &.click-on {
        color: #fff;
        background: #333;
      }
    }

    .room-info {
      font-size: 12px;
    }
  }

  button.confirm-button {
    width: 100%;
    height: 48px;
    padding: 0;
    background-color: #333;
    border: unset;
    border-radius: 8px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #fff;
  }
  button.confirm-button:disabled {
    background-color: rgb(203, 204, 205);
  }
  .non-data {
    padding: 32px 16px;
  }
`;

const Styled = {
  RoomSelect,
};

export default Styled;
