import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import MainLayout from '../components/layouts/MainLayout';
import CheckIn from '../components/views/CheckIn';
import { bookingListAction } from '../stores/actions';
import * as GoogleAnalytics from '../lib/google-analytics';
import { RootState } from '../stores/reducers';
import * as Sentry from '@sentry/react';

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const CheckInContainer = (props:Props) => {
  const navigation = useNavigate();
  const { bookingItem } = useSelector((state:RootState) => state.bookingList);
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  
  const dispatch = useDispatch();
  const { Kakao } = window as any;

  const loginWithKakao = useCallback(() => {
    if(isTestMode){
      navigation('/checkin/payment');
      return;
    }
    try {
      Kakao.Auth.login({
        success: function(response:any) {
          getUserInfoWithKakao();
        },
        fail: function(error:any) {
          console.log(error);
        },
      });
    } catch (error) {
      Sentry.captureMessage(`loginWithKakao_Error : ${error} (예약번호:${bookingItem.rsvnNo})`);
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfoWithKakao = useCallback(async () => {
    try {
      Kakao.API.request({
        url: '/v2/user/me',
        success: function(response:any) {
          const parsedPhoneNumber = `0${response.kakao_account.phone_number.split(' ')[1].replace(/-/gi, '')}`;
          dispatch(bookingListAction.setUserInfo({
            phoneNumber: parsedPhoneNumber,
            email: response.kakao_account.email,
            hotel_id: "497db38cb747f1bbb3ba58cd960e517e67c2a9ba",
            account_email: response?.kakao_account?.email,
            name: response?.kakao_account?.name,
            gender: response?.kakao_account?.gender,
            age_range: response?.kakao_account?.age_range,
            birthday: response?.kakao_account?.birthday,
            birthyear: response?.kakao_account?.birthyear,
            phone_number: response?.kakao_account?.phone_number,
          }));
          navigation('/checkin/payment');
        },
        fail: function(error:any) {
          Sentry.captureMessage(`getUserInfoWithKakao_Error : ${error} (예약번호:${bookingItem.rsvnNo})`);
          console.log(error);
        },
      });
    } catch (error) {
      Sentry.captureMessage(`getUserInfoWithKakao_Error : ${error} (예약번호:${bookingItem.rsvnNo})`);
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIn = useCallback(async () => {
    try {
      loginWithKakao();
    } catch (error) {
      console.log(error);
    }
  }, [loginWithKakao]);

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== 'RR') navigation(`/?rsvnNo=${bookingItem.rsvnNo}`);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth',
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <CheckIn
            checkIn={checkIn}
          />
        )}
      />
    </div>
  );
};

export default CheckInContainer;