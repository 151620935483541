import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import Payment from '../components/views/Payment';
import ModalError from '../components/ModalError';
import * as kiccApi from '../api/kicc';
import * as GoogleAnalytics from '../lib/google-analytics';
import Spinner from '../components/Spinner';
import { RootState } from '../stores/reducers';
import { useNavigate } from 'react-router-dom';
import * as sanhaApi from '../api/sanha';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { bookingListAction } from '../stores/actions';


type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const CheckInPaymentContainer = (props:Props) => {
  const navigation = useNavigate();
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ isError, setIsError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const { bookingItem, paymentInfo, queryStringParams } = useSelector((state:RootState) => state.bookingList);
  const [ totalAmount, setTotalAmount ] = useState(bookingItem.roomRate);
  const [ isLoading, setIsLoading ] = useState(false);
  const dispatch = useDispatch();


  const registTransaction = async () => {
    try {
      const { data: registedTransaction } = await kiccApi.registTransaction({
        mallId: process.env.REACT_APP_KICC_MALL_ID,
        payMethodTypeCode: '11',
        currency: '00',
        amount: totalAmount || 1000,
        clientTypeCode: '00',
        returnUrl: process.env.REACT_APP_KICC_CALLBACK_URL,
        deviceTypeCode: 'mobile',
        shopOrderNo: bookingItem.rsvnNo,
        orderInfo: {
          goodsName: `[${bookingItem.roomTypeCode}] ${bookingItem.roomTypeDesc}`,
        },
      });
      return registedTransaction;
    } catch (error) {
      Sentry.captureMessage(`registTransaction_Error : ${error} (예약번호:${bookingItem.rsvnNo})`);
      throw error;
    }
  };

  const openModalError = useCallback(() => {
    setIsOpenModalError(true);
    setIsError(true);
  }, []);

  const closeModalError = useCallback(() => {
    setIsOpenModalError(false);
  }, []);

  //PG 성공 했지만, 산하 결재 내역에서 실패 했을 경우
  const registCheckInPayment = useCallback(async () => {
    try {
      console.log(queryStringParams);
      Sentry.captureMessage(`payment_CheckIn_Page_success : 결제 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`);
        const response = await sanhaApi.registCheckInPayment({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: '1',
          trnsNo: queryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: 'P', //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format('YYYYMMDD'), //인터넷 지불 일자
          successYn: queryStringParams.successYn,
          trnsDate: moment().format('YYYYMMDD'), //거래일자
          trnsHHMMSS: moment().format('HHmmss'),
          trnsAmt: queryStringParams.trnsAmt,
          cardMonthCnt: queryStringParams.cardMonthCnt || '', //할부 개월 수
          cardApprNo: queryStringParams.trnsNo || '', //카드 승인 번호 (간편결제 시 null이라서 pgCno 값 적용)
          cardName: queryStringParams.cardName || '', //카드명
          cardIssueCode: queryStringParams.cardIssueCode || '', //카드 발급사 코드
          cardPurcCode: queryStringParams.cardPurcCode || '', //카드 매입사 코드
          trnsBankCode: '', //거래 은행 코드
          acctNo: '', //계좌번호
          depositor: queryStringParams.depositor || '', //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: '', //현금 영수증 승인 번호
          cashRcptCxlApptNo: '', //현금 영수증 취소 승인 번호
          responseCode: queryStringParams.responseCode || '',
          message1: queryStringParams.responseMessage || '',
          message2: '',
          dpsitPayDivCode: '01',
          userId: 'KEYLESS',
          userIp: '127.0.0.0',
          payMgtNo: '',
          dpsitPayNo: '',
          outYN: '',
          outMsg: '',
        });
        if (response.code !== '0000') {
          Sentry.captureMessage(`registCheckInPayment_response_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`);
          setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
          throw new Error(`${response.code}, ${response.message}`);
        }else{
          dispatch(bookingListAction.setPaymentInfo({rsvnNo: bookingItem.rsvnNo, isPaid: true}));
          navigation('/room');
        }
    } catch (error:any) {
      Sentry.captureMessage(`registCheckInPayment_response2_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`);
      setModalErrorMessage('프론트를 방문해주세요.');
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const confirmCheckInPayment = async () => {
    try {
      setIsLoading(true);
      //결제 여부 체크
      const confirmCheckInPaymentResponse = await sanhaApi.confirmCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: '1',
      });
      if (confirmCheckInPaymentResponse.code !== '0000') {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_fail_confirm_checkin_payment',
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.message}`,
        });
        
        Sentry.captureMessage(`confirmCheckInPayment_Error : ${confirmCheckInPaymentResponse.code}: ${confirmCheckInPaymentResponse.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage('예약 결제 여부 확인에 실패 하였습니다.');
        throw new Error(`${confirmCheckInPaymentResponse.code}, ${confirmCheckInPaymentResponse.message}`);
      } else if (confirmCheckInPaymentResponse.data.payYn === 'Y' && bookingItem.caclPayCode === '6') {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_ok_confirm_checkin_payment',
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.data.payYn}`,
        });
        navigation('/room');
      } else if(bookingItem.caclPayCode === '6') {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_ok_confirm_checkin_payment',
          label: `${confirmCheckInPaymentResponse.code}_${confirmCheckInPaymentResponse.data.payYn}`,
        });
        navigation('/room');
      } else {
        //미결제 금액 확인
        const confirmCheckInAmountResponse = await sanhaApi.confirmCheckInAmount({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: '1',
        });
        if (confirmCheckInAmountResponse.code !== '0000') {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_fail_confirm_checkin_amount',
            label: `${confirmCheckInAmountResponse.code}_${confirmCheckInAmountResponse.message}`,
          });
          
          Sentry.captureMessage(`confirmCheckInPayment_Amount_Error : 예약 결제 금액 확인에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`);
          setModalErrorMessage('예약 결제 금액 확인에 실패 하였습니다.');
          throw new Error(`${confirmCheckInAmountResponse.code}, ${confirmCheckInAmountResponse.message}`);
        } else if (!confirmCheckInAmountResponse.data.totalAmount) {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_ok_confirm_checkin_amount',
            label: `${confirmCheckInAmountResponse.code}_${confirmCheckInAmountResponse.data.totalAmount}`,
          });
          navigation('/room');
        }else {
          setTotalAmount(confirmCheckInAmountResponse.data.totalAmount);
        }
      }
    } catch (error:any) {
      Sentry.captureMessage(`confirmCheckInPayment_response_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`);
      setModalErrorMessage('프론트를 방문해주세요.');
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paymentInfo && paymentInfo.isPaid && paymentInfo.rsvnNo === bookingItem.rsvnNo) navigation('/room'); //결제 이력이 있을 경우 바로 객실 선택으로
    else if(bookingItem.caclPayCode === '5' && queryStringParams && queryStringParams.responseCode === '0000' && 
    !paymentInfo.isPaid && queryStringParams.shopOrderNo === bookingItem.rsvnNo) registCheckInPayment(); //pg 결제는 저장한 상태지만 payment는 없을 경우 결제 이력 저장화면 전환
    else confirmCheckInPayment(); //결제 금액 체크
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkin_payment',
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
    });
  }, [bookingItem.guestName, bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <Payment
            bookingItem={bookingItem}
            registTransactionAPI={registTransaction}
            amount={totalAmount}
            setModalErrorMessage={setModalErrorMessage}
            setModalErrorSubMessage={setModalErrorSubMessage}
            openModalError={openModalError}
            isError={isError}
            errorMessage={modalErrorSubMessage}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </div>
  );
};

export default CheckInPaymentContainer;