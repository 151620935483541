
export const roomNoFormatter = (roomNo:string) => {

  return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
};

export const roomNoFormatter2 = (roomNo:string) => {
  const zone = parseInt(roomNo.slice(0, 1));
  
  const floor = parseInt(roomNo.slice(1, 2));

  const zoneName = zone === 1 ? '101동' : zone === 2 ? '102동' : zone === 3 ? '103동' : zone === 4 ? '104동' : zone === 5 ? '105동' : '';

  const floorName = `${floor}층`

  return `${zoneName} ${floorName}`;
};


