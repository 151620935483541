import React, { Fragment } from 'react';

const FrontInfo = () => {
  return (
    <Fragment>
      <div className='front-info-container'>
        <img className='front-success ml-10' src='../../assets/images/check-out-success.png' alt='' />
        <span className='front-success-title'>
          CHECKIN INFOMATION
        </span>
        <span className='front-success-sub-description'>
          체크인 진행을 위해 프론트로 방문해주시기 바랍니다.
        </span>
      </div>
    </Fragment>
  );
};

export default FrontInfo;