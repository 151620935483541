// 현재 파일은 기본 제공 디자인입니다. 수정하지 말아주세요.
import styled from "styled-components";
import BaseTermsOfUseListContainer from "./containers/TermsOfUseListContainer";
import BasePage404 from "./components/views/Page404";
import BasePagePC from "./components/views/PagePC";
import InitialBookingContainer from "./containers/BookingListContainer";

//예약 첫 화면
const BookingList = styled(InitialBookingContainer)`
  .initial-booking-welcome-message-container {
    margin: 0 0;
    padding: 0 0;
  }
  .initial-booking-welcome-message {
    display: block;
    margin: 48px 0 24px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #333;
  }
  .initial-booking-card-container {
    padding: 0 0 20px;
    margin: 24px 0 0;
    border-radius: 20px;
    box-shadow: 3px 3px 16px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  .initial-booking-card-container .hotel-img-container {
    width: 100%;
    height: 0;
    padding-top: calc(500 / 1000 * 100%);
    margin: 0 0 16px;
    background: url("./assets/images/cistart-hotel.jpg") center center / cover
      no-repeat;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .banner-img1 {
    width: 40vh;
    height: 20vh;
    margin: 0 auto 5px;
    background: url("https://drive.google.com/uc?export=view&id=1DmdxVwRfp2ciD7ip4ejdSiwMDfGaSfHU")
      no-repeat scroll 0 0 / cover;
  }
  .banner-img2 {
    width: 40vh;
    height: 20vh;
    margin: 0 auto 5px;
    background: url("https://drive.google.com/uc?export=view&id=1nVGFAvqzUN2V5IEO436HsfrVd2KUlOQq")
      no-repeat scroll 0 0 / cover;
  }
  .banner-img3 {
    width: 40vh;
    height: 20vh;
    margin: 0 auto 5px;
    background: url("https://drive.google.com/uc?export=view&id=10WO9ZRvwlK9op2MNpOgYGzWAVI1DzO5V")
      no-repeat scroll 0 0 / cover;
  }
  .initial-booking-card-container .booking-period {
    display: block;
    height: 18px;
    margin: 12px 12px 2px;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333;
  }
  .initial-booking-card-container .hotel-name {
    display: block;
    height: 24px;
    margin: 2px 12px 12px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    color: #333;
  }
  .initial-booking-card-container .no-booking {
    display: block;
    height: 24px;
    margin: 2px 12px 12px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    color: #333;
  }
  .initial-booking-card-container .user-info-container {
    display: flex;
    height: 16px;
    margin: 0 12px 12px;
  }
  .initial-booking-card-container .user-info-container img.user-icon {
    width: 13px;
    height: 13px;
    object-fit: contain;
  }
  .initial-booking-card-container .user-info-container .user-name {
    margin: -1px 0 0 2px;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.4px;
    color: #333;
  }
  .initial-booking-card-container .user-info-container .booking-number {
    margin: -1px 0 0 6px;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #999;
  }
  .initial-booking-card-container .check-in-button {
    height: 40px;
    margin: 19px 20px 0;
    border-radius: 8px;
    background-color: #333;
  }
  .initial-booking-card-container .check-in-button.disabled {
    background-color: #f4f4f4;
  }
  .initial-booking-card-container .check-in-button .check-in-button-text {
    display: block;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 40px;
    letter-spacing: -0.4px;
    text-align: center;
    color: #fff;
  }
  .initial-booking-card-container
    .check-in-button.disabled
    .check-in-button-text {
    color: #d6d6d6;
  }

  .checked-in-background-container {
    height: 216px;
    opacity: 0.8;
    width: 100%;
    background: url("./assets/images/cistart-hotel.jpg") center top / cover
      no-repeat;
  }
  .checked-in-background-container .opacity-background {
    width: 100%;
    height: 216px;
    background-color: rgb(0, 0, 0, 0.6);
  }
  .checked-in-background-container .opacity-background .welcome-message {
    display: block;
    height: 88px;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: -0.8px;
    color: #fff;
    padding: 24px 0 0 24px;
  }
  .checked-in-background-container .opacity-background .booking-id-container {
    display: flex;
    float: right;
    width: 141px;
    height: 28px;
    padding: 6px 12px;
    margin: 43px 24px 0 0;
    border-radius: 14px;
    background-color: #333;
  }
  .checked-in-background-container
    .opacity-background
    .booking-id-container
    .booking-id-label {
    display: block;
    width: 37px;
    height: 12px;
    margin: 2px 6px 2px 0;
    opacity: 0.8;
    font-size: 10px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .checked-in-background-container
    .opacity-background
    .booking-id-container
    .booking-id-value {
    display: block;
    width: 74px;
    height: 16px;
    opacity: 0.8;
    font-size: 11px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .checked-in-booking-card-container {
    position: absolute;
    top: 175px;
    width: 100%;
    padding: 24px 30px 78px;
    border-radius: 24px;
    background-color: #fff;
  }
  .checked-in-booking-card-container .hotel-name {
    display: block;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    color: #333;
  }
  .checked-in-booking-card-container .room-name {
    display: block;
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #333;
  }
  .checked-in-booking-card-container .userkey-container {
    margin: 0 0 20px;
  }
  .checked-in-booking-card-container .userkey-container .userkey-item {
    width: 100%;
    margin: auto;
    padding: 10px 0;
    background-color: #c3d3e7;
    border-radius: 12px;
  }
  .checked-in-booking-card-container .userkey-container .userkey-item-label {
    display: block;
    height: 18px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.4px;
    text-align: center;
  }
  .checked-in-booking-card-container .userkey-container .userkey-item-value {
    display: block;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #333;
    word-break: break-word;
  }
  img.tip-icon {
    width: 14px;
    height: 14px;
    object-fit: contain;
  }
  .checked-in-booking-card-container .checked-in-booking-period-container {
    display: flex;
    height: 74px;
    padding: 16px 8.89%;
    margin: 0 0 16px;
    border-radius: 12px;
    background-color: #f9f9f9;
  }
  @media (min-width: 550px) {
    .checked-in-booking-card-container .checked-in-booking-period-container {
      padding: 16px 17.89%;
    }
  }
  img.period-icon {
    width: 12px;
    height: 37px;
    object-fit: contain;
  }
  .checked-in-booking-card-container
    .checked-in-booking-period-container
    .period-item-container {
    width: 100%;
    margin: 0 0 0 11px;
  }
  .checked-in-booking-card-container
    .checked-in-booking-period-container
    .period-item-container
    .period-item {
    display: flex;
    position: relative;
  }
  .checked-in-booking-card-container
    .checked-in-booking-period-container
    .period-item-container
    .period-item
    .period-item-label {
    display: block;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 16px;
    letter-spacing: -0.4px;
    color: #666;
  }
  .checked-in-booking-card-container
    .checked-in-booking-period-container
    .period-item-container
    .period-item
    .period-item-value {
    display: block;
    position: absolute;
    right: 0;
    font-size: 13px;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: right;
    color: #333;
  }
  .checked-in-booking-card-container .direct-booking-banner-container {
    display: flex;
    position: relative;
    margin: 0 0 16px;
    padding: 16px 7.62%;
    border-radius: 16px;
    background-color: #eef2f3;
  }
  @media (min-width: 550px) {
    .checked-in-booking-card-container .direct-booking-banner-container {
      padding: 16px 17.62%;
    }
  }
  .checked-in-booking-card-container
    .direct-booking-banner-container
    .direct-booking-banner-title {
    display: block;
    margin: 4px 0;
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: -0.2px;
    color: #333;
  }
  .checked-in-booking-card-container
    .direct-booking-banner-container
    .direct-booking-banner-marketing-message {
    display: block;
    margin: 4px 0;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.4px;
    color: #666;
  }
  .checked-in-booking-card-container
    .direct-booking-banner-container
    .room-image-container {
    position: absolute;
    right: 7.62%;
  }
  @media (min-width: 550px) {
    .checked-in-booking-card-container
      .direct-booking-banner-container
      .room-image-container {
      right: 17.62%;
    }
  }
  img.room-image {
    width: 72px;
    height: 72px;
    border-radius: 36px;
  }
  .checked-in-booking-card-container .check-out-button-container {
    height: 38px;
    padding: 8px 0;
  }
  .checked-in-booking-card-container
    .check-out-button-container
    .check-out-button {
    display: block;
    margin: auto;
    padding: 12px 0;
    border-radius: 8px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 22px;
    letter-spacing: -0.4px;
    text-align: center;
    color: #fff;
    background-color: #333;
  }
`;

//이용 약관
const TermsOfUseListContainer = styled(BaseTermsOfUseListContainer)`
  .terms-of-use-list-title {
    display: block;
    margin: 30px 0 10px;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: -0.8px;
    color: #333;
  }
  .terms-of-use-list-description {
    display: block;
    margin: 10px 0 24px;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.4px;
    color: #666;
  }
  .agree-checkbox {
    display: flex;
    width: 18px;
    height: 18px;
    margin: 0px 8px 0 0;
    padding: 4px;
    border-radius: 6px;
    border: solid 1px #ebebeb;
    background-color: #fff;
  }
  .agree-checkbox.checked {
    border: solid 1px #000;
    background-color: #000;
  }
  .agree-checkbox img.checkbox {
    width: 10px;
    height: 10px;
    object-fit: contain;
  }
  .all-agree-checkbox-container {
    padding: 15px 0 17px;
    display: flex;
  }
  .all-agree-checkbox-container .all-agree-checkbox-text {
    margin: -1px 4px 0 0px;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #333;
  }
  .all-agree-checkbox-container .all-agree-checkbox-description {
    margin: -1px 0 0;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #b6b6b6;
  }
  .horizontal-line {
    height: 1px;
    background-color: #ebebeb;
  }
  .agree-checkbox-container {
    display: flex;
    position: relative;
    margin: 2px 0;
    padding: 7px 0 9px;
  }
  .agree-checkbox-container .agree-checkbox-text {
    margin: -1px 18px 0 0;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    color: #333;
  }
  .agree-checkbox-container img.detail-view {
    position: absolute;
    right: 0;
    width: 18px;
    height: 18px;
    object-fit: contain;
  }
  button.confirm-button {
    width: 100%;
    height: 48px;
    padding: 0;
    background-color: #333;
    border: unset;
    border-radius: 8px;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.2px;
    color: #fff;
  }
  button.confirm-button:disabled {
    background-color: rgb(203, 204, 205);
  }
`;

//PC 접근 관련 페이지
const PagePC = styled(BasePagePC)`
  .on-pc-icon {
    text-align: center;
    font-size: 100px;
    color: #faad14;
  }
  .on-pc-status-code {
    display: block;
    margin: 14px 0;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .on-pc-status-message {
    display: block;
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
`;

//404 에러 페이지
const Page404 = styled(BasePage404)`
  .not-found-icon {
    text-align: center;
    font-size: 100px;
    color: #faad14;
  }
  .not-found-code {
    display: block;
    margin: 14px 0;
    font-size: 22px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.4px;
    text-align: center;
    color: #000;
  }
  .not-found-message {
    display: block;
    margin: 0 0 20px;
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: -0.4px;
    text-align: center;
    word-break: keep-all;
    color: #666;
  }
`;

const Styled = {
  BookingList,
  TermsOfUseListContainer,
  PagePC,
  Page404,
};

export default Styled;
