import React, { useState, useEffect, useCallback } from 'react';
import MainLayout from '../components/layouts/MainLayout';
import PaymentLoading from '../components/views/PaymentLoading';
import ModalError from '../components/ModalError';
import PaymentError from '../components/PaymentError';
import Spinner from '../components/Spinner';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../stores/reducers';
import * as api from "../api/sanha";
import moment from 'moment';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const CheckOutPaymentResponseContainer = (props:Props) => {

  const navigation = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const urlQueryStringParams:any = queryString.parse(searchParams.toString());
  const { bookingItem } = useSelector((state:RootState) => state.bookingList);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isOpenPaymentError, setIsOpenPaymentError ] = useState<boolean>(false);
  const [ paymentErrorMessage, setPaymentErrorMessage ] = useState<string>('');
  const [ isOpenModalError, setIsOpenModalError ] = useState<boolean>(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState<string>('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState<string>('');

  const openPaymentError = () => {
    setIsOpenPaymentError(true);
  };

  const closePaymentError = () => {
    navigation('/checkout/payment');
    setIsOpenPaymentError(false);
  };

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    navigation(`/?rsvnNo=${bookingItem.rsvnNo}`); //첫화면으로 원복
  };

  const registCheckOutPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.responseCode !== '0000') {
        setModalErrorMessage('결제 요청에 실패 하였습니다.');
        throw new Error('');
      } else {
        setIsLoading(true);
        const registCheckOutPaymentResponse = await api.registCheckOutPayment({
          folioNo: bookingItem.folioNo,
          trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: 'P', //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format('YYYYMMDD'), //인터넷 지불 일자
          successYn: urlQueryStringParams.successYn,
          trnsDate: moment().format('YYYYMMDD'), //거래일자
          trnsHHMMSS: moment().format('HHmmss'),
          trnsAmt: urlQueryStringParams.trnsAmt,
          cardMonthCnt: urlQueryStringParams.cardMonthCnt || '', //할부 개월 수
          cardApprNo: urlQueryStringParams.cardApprNo || '', //카드 승인 번호
          cardName: urlQueryStringParams.cardName || '', //카드명
          cardIssueCode: urlQueryStringParams.cardIssueCode || '', //카드 발급사 코드
          cardPurcCode: urlQueryStringParams.cardPurcCode || '', //카드 매입사 코드
          trnsBankCode: '', //거래 은행 코드
          acctNo: '', //계좌번호
          depositor: urlQueryStringParams.depositor || '', //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: '', //현금 영수증 승인 번호
          cashRcptCxlApptNo: '', //현금 영수증 취소 승인 번호
          responseCode: urlQueryStringParams.responseCode || '',
          message1: urlQueryStringParams.responseMessage || '',
          message2: '',
          dpsitPayDivCode: '01',
          userId: 'KEYLESS',
          userIp: '127.0.0.0',
          payMgtNo: '',
          dpsitPayNo: '',
          outYN: '',
          outMsg: '',
        });
        if (registCheckOutPaymentResponse.code !== '0000') {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_fail_regist_checkout_payment',
            label: `${registCheckOutPaymentResponse.code}_${registCheckOutPaymentResponse.message}`,
          });
          Sentry.captureMessage(`registCheckOutPayment_response_Error : 결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다. (예약번호:${bookingItem.rsvnNo})`);
          setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
          throw new Error('프론트로 문의 바랍니다.');
        } else {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_ok_regist_checkout_payment',
          });
          const checkOutResponse = await api.checkOut({
            folioNo: bookingItem.folioNo,
            earlyCheckoutYN: 'N',
          });
          if (checkOutResponse.code !== '0000') {
            GoogleAnalytics.customEvent({
              category: 'api_response',
              action: 'api_response_fail_checkout_request',
              label: `${checkOutResponse.code}_${checkOutResponse.message}`,
            });
            Sentry.captureMessage(`registCheckOutPayment_response2_Error : 결제는 성공하였으나, 체크아웃에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`);
            setModalErrorMessage('결제는 성공하였으나, 체크아웃에 실패 하였습니다.');
            throw new Error('프론트로 문의 바랍니다.');
          } else {
            GoogleAnalytics.customEvent({
              category: 'api_response',
              action: 'api_response_ok_checkout_request',
            });
            navigation('/checkout/payment/success');
          }
        }
      }
    } catch (error:any) {
      
      if (!urlQueryStringParams || urlQueryStringParams.responseCode !== '0000') {
        Sentry.captureMessage(`registCheckOutPayment_Error : ${urlQueryStringParams.responseCode}: ${urlQueryStringParams.responseMessage} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorSubMessage(urlQueryStringParams.responseMessage);
      }else{
        Sentry.captureMessage(`registCheckOutPayment_error_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage('프론트를 방문해주세요.');
        setModalErrorSubMessage(error.message);
      }
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registCheckOutPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkout_payment_response',
    });
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <PaymentLoading />
        )}
      />
      <PaymentError
        isOpen={isOpenPaymentError}
        message={paymentErrorMessage}
        onClose={closePaymentError}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </div>
  );
};

export default CheckOutPaymentResponseContainer;