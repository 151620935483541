import React, { Fragment } from "react";

const CheckOutPaymentSuccess = () => {
  return (
    <Fragment>
      <div className="check-out-success-info-container">
        <img
          style={{ width: "80px", height: "80px" }}
          src="../../assets/images/cistar-logo.png"
          alt="심볼 이미지"
        />
        <div style={{ margin: "60px 0" }}>
          <span className="check-out-success-title">CHECK OUT</span>
          <span className="check-out-success-description">
            체크아웃이 완료 되었습니다.
          </span>
          <span className="check-out-success-sub-description">
            안전하고 편안한 귀갓길 되시기 바랍니다.
            <br />
            항상 보다 나은 서비스로 고객님의 성원에 보답하겠습니다. 감사합니다.
          </span>
        </div>
        <img
          style={{ width: "220px", height: "140px" }}
          src="../../assets/images/cistar-content.png"
          alt="워드마크 이미지"
        />
      </div>
    </Fragment>
  );
};

export default CheckOutPaymentSuccess;
