import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MainLayout from '../components/layouts/MainLayout';
import PaymentLoading from '../components/views/PaymentLoading';
import ModalError from '../components/ModalError';
import * as api from '../api/sanha';
import { RootState } from '../stores/reducers';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import * as Sentry from '@sentry/react';
import { bookingListAction } from '../stores/actions';
import _ from 'lodash';

type Props = JSX.IntrinsicAttributes & { children?: React.ReactNode; };

const CheckInPaymentResponseContainer = (props:Props) => {
  const navigation = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();
  const urlQueryStringParams:any = queryString.parse(searchParams.toString());
  const { bookingItem, paymentInfo } = useSelector((state:RootState) => state.bookingList);
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');  
  const dispatch = useDispatch();


  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    //navigation(`/`);
  };

  const registCheckInPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.responseCode !== '0000') {
        setModalErrorMessage('결제 요청에 실패 하였습니다.');
        throw new Error('');
      }else{
        console.log(urlQueryStringParams);
        Sentry.captureMessage(`payment_success : 결제 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`);
        const registCheckInPaymentResponse = await api.registCheckInPayment({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: '1',
          trnsNo: urlQueryStringParams.shopOrderNo, //거래 승인 번호
          intApprTypeCode: 'P', //인터넷 결제 유형 코드
          intPayStatusCode: 11, //인터넷 결제 상태 코드
          payFnshDate: moment().format('YYYYMMDD'), //인터넷 지불 일자
          successYn: urlQueryStringParams.successYn,
          trnsDate: moment().format('YYYYMMDD'), //거래일자
          trnsHHMMSS: moment().format('HHmmss'),
          trnsAmt: urlQueryStringParams.trnsAmt, //결제 금액
          cardMonthCnt: urlQueryStringParams.cardMonthCnt || '', //할부 개월 수
          cardApprNo: urlQueryStringParams.trnsNo || '', //카드 승인 번호 (간편결제 시 null이라서 pgCno 값 적용)
          cardName: urlQueryStringParams.cardName || '', //카드명
          cardIssueCode: urlQueryStringParams.cardIssueCode || '', //카드 발급사 코드
          cardPurcCode: urlQueryStringParams.cardPurcCode || '', //카드 매입사 코드
          trnsBankCode: '', //거래 은행 코드
          acctNo: '', //계좌번호
          depositor: urlQueryStringParams.depositor || '', //예금주명
          cashRcptTypeCode: 0, //현금 영수증 발급 구분 코드
          cashRcptApptNo: '', //현금 영수증 승인 번호
          cashRcptCxlApptNo: '', //현금 영수증 취소 승인 번호
          responseCode: urlQueryStringParams.responseCode || '',
          message1: urlQueryStringParams.responseMessage || '',
          message2: '',
          dpsitPayDivCode: '01',
          userId: 'KEYLESS',
          userIp: '127.0.0.0',
          payMgtNo: '',
          dpsitPayNo: '',
          outYN: '',
          outMsg: '',
        });
        if (registCheckInPaymentResponse.code !== '0000') {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_fail_regist_checkin_payment',
            label: `${registCheckInPaymentResponse.code}_${registCheckInPaymentResponse.message}`,
          });

          Sentry.captureMessage(`registCheckInPayment_response_Error : ${registCheckInPaymentResponse.code}: ${registCheckInPaymentResponse.message} (예약번호:${bookingItem.rsvnNo})`);
          setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
          throw new Error(`${registCheckInPaymentResponse.code}, ${registCheckInPaymentResponse.message}`);
        }else{
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_ok_regist_checkin_payment',
          });
          dispatch(bookingListAction.setPaymentInfo({rsvnNo: bookingItem.rsvnNo, isPaid: true}));
          navigation('/room');
        }
      }
    } catch (error:any) {
      if (!urlQueryStringParams || urlQueryStringParams.responseCode !== '0000') {
        Sentry.captureMessage(`registCheckInPayment_Error : 결제 요청에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorSubMessage(urlQueryStringParams.responseMessage);
      }else{
        Sentry.captureMessage(`registCheckInPayment_response2_Error : ${error.response?.data?.message} || ${error.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage('프론트를 방문해주세요.');
        setModalErrorSubMessage(error.message);
      }
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(urlQueryStringParams)) dispatch(bookingListAction.setBookingQueryStringParams(urlQueryStringParams));
    if(paymentInfo.isPaid) {
      navigation('/room');
      return;
    }
    registCheckInPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkin_payment_result',
      label: urlQueryStringParams ? `${urlQueryStringParams.ResultCode}_${urlQueryStringParams.ResultMsg}}` : 'null',
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div {...props}>
      <MainLayout
        ContentBody={(
          <PaymentLoading />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </div>
  );
};

export default CheckInPaymentResponseContainer;