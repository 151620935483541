import { Fragment, useState, useCallback } from "react";
import _ from "lodash";
import CheckInConfirm from "./CheckInConfirm";
import * as GoogleAnalytics from "../../lib/google-analytics";
import { roomNoFormatter } from "../../lib/formatter";
import * as api from "../../api/sanha";
import jsonRoomList from "../../assets/roomList.json";
import roomBedTypeList from "../../assets/roomBedType.json";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface RoomBedTypeList {
  [roomNo: string]: string;
}

interface RoomSelectProps {
  totalRoomList: api.listAvailableRoomServerApi[];
  buildingCodeList: string[];
  buildingNameList: string[];
  floorCodeList: string[];
  floorNameList: string[];
  roomList: api.listAvailableRoomServerApi[];
  selectedRoom: any;
  selectedBuilding: string;
  selectedFloor: string;
  changeBuilding: (building: string) => void;
  changeFloor: (floor: string) => void;
  changeRoom: (room: api.listAvailableRoomServerApi) => void;
  checkIn: () => void;
}

const RoomSelect = ({
  totalRoomList,
  buildingCodeList,
  buildingNameList,
  floorCodeList,
  floorNameList,
  roomList,
  selectedRoom,
  selectedBuilding,
  selectedFloor,
  changeBuilding,
  changeFloor,
  changeRoom,
  checkIn,
}: RoomSelectProps) => {
  const roomSettingList = Object.values(jsonRoomList);
  const data = roomBedTypeList as RoomBedTypeList;

  const [isOpenModalCheckInConfirm, setIsOpenModalCheckInConfirm] =
    useState(false);

  const openModalCheckInConfirm = useCallback(() => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_room_select",
    });
    setIsOpenModalCheckInConfirm(true);
  }, []);

  const closeModalCheckInConfirm = useCallback(() => {
    setIsOpenModalCheckInConfirm(false);
  }, []);

  return (
    <Fragment>
      <div className="image-view" style={{ width: "100%" }}>
        <TransformWrapper initialScale={1} minScale={1} maxScale={10}>
          <TransformComponent>
            <figure style={{ margin: 0 }}>
              <img
                src="./assets/images/panorama-view.png"
                style={{ width: "110%", height: "250px" }}
                alt="동강시스타 지도 이미지 "
              />
            </figure>
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div className="room-main-title">객실 선택</div>
      {totalRoomList.length ? (
        <div className="room-container">
          <div className="room-title">빌딩</div>
          <ul className="building-list-container">
            {buildingCodeList.map((building: string, index: number) => {
              return (
                <li
                  key={building}
                  className={building === selectedBuilding ? "click-on" : ""}
                  onClick={() => changeBuilding(building)}
                >
                  {buildingNameList[index]}
                </li>
              );
            })}
          </ul>
          {/* <div className='room-title'>층</div>
          <ul className='floor-list-container'>
            {floorCodeList.map((floor:string, index:number) => {
              return <li key={floor} className={floor === selectedFloor? 'click-on' : ''} onClick={() => changeFloor(floor)}>{floorNameList[index]}</li>
            })}
          </ul> */}
          <div className="room-title">객실</div>
          <ul className="room-list-container">
            {roomList.map((roomItem: api.listAvailableRoomServerApi) => {
              return (
                <li
                  key={roomItem.roomNo}
                  className={
                    roomItem.roomNo === selectedRoom.roomNo ? "click-on" : ""
                  }
                  onClick={() => changeRoom(roomItem)}
                >
                  {roomNoFormatter(roomItem.roomNo)}
                  <div className="room-info">
                    {roomSettingList[0].includes(roomItem.roomNo)
                      ? "전망"
                      : "비전망"}
                    {data[roomItem.roomNo] ? ` / ${data[roomItem.roomNo]}` : ""}
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="mt-26">
            <button
              className="confirm-button"
              onClick={openModalCheckInConfirm}
              disabled={_.isEmpty(selectedRoom)}
            >
              체크인 하기
            </button>
          </div>
        </div>
      ) : (
        <div className="non-data">선택할 수 있는 객실이 없습니다.</div>
      )}
      <CheckInConfirm
        isOpen={isOpenModalCheckInConfirm}
        selectedRoom={selectedRoom}
        onClose={closeModalCheckInConfirm}
        checkIn={checkIn}
      />
    </Fragment>
  );
};

export default RoomSelect;
