import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_IMGATE_BACKEND_BASEURL;

// config
const request = axios.create({
  baseURL,
});

const headers = (header:any) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Charset': 'utf-8',
      ...header && header,
    }
  };
};

export interface kakaoUserDataProps {
  hotel_id: string,
  account_email?: string,
  name?: string,
  gender?: string,
  age_range?: string,
  birthday?: string,
  birthyear?: string,
  phone_number?: string,
}

type ServerState<A> = {
  code: string,
  message: string,
  data: A,
}

export const kakaoUserData = ({hotel_id, account_email, name, gender, age_range, birthday, birthyear, phone_number}:kakaoUserDataProps):Promise<ServerState<any>> => {
  const data = {hotel_id, account_email, name, gender, age_range, birthday, birthyear, phone_number};
  return request.post('/multifamily/kakao_user_data',
    data,
    headers({}),
  );
};